<script>

export default {
  data() {
    return {
      currentTimeout: undefined,
    }
  },
  mounted(){
    this.removeZohoWidget()
    var f = document.getElementById("zf_div_6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg");
    f.style.border="none";
    f.style.height="720px";
    f.style.transition="all 0.5s ease";
    f.setAttribute("aria-label", 'Sorteo\x20Cluber\x20Parque\x20Warner');
    window.addEventListener('message', function (){
    var evntData = event.data;
    if( evntData && evntData.constructor == String ){
    var zf_ifrm_data = evntData.split("|");
    if ( zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3 ) {
    var zf_perma = zf_ifrm_data[0];
    var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
    var iframe = document.getElementById("zf_div_6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg");
    if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
    var prevIframeHeight = iframe.style.height;
    var zf_tout = false;
    if( zf_ifrm_data.length == 3 ) {
    iframe.scrollIntoView();
    zf_tout = true;
    }
    if ( prevIframeHeight != zf_ifrm_ht_nw ) {
    if( zf_tout ) {
    setTimeout(function(){
    iframe.style.height = zf_ifrm_ht_nw;
    },500);
    } else {
    iframe.style.height = zf_ifrm_ht_nw;
    }
    }
    }
    }
    }
    }, false);
  },
  beforeDestroy(){
    if (this.currentTimeout) clearTimeout(this.currentTimeout)
    $zoho.salesiq.floatbutton.visible("show")
  },
  methods: {
    scrollToForm() {
      document.getElementById('formulario-inscripcion').scrollIntoView({ behavior: 'smooth'})
    },
    removeZohoWidget() {
      if (typeof $zoho === 'undefined') {
        this.currentTimeout = setTimeout(this.removeZohoWidget, 100)
        return
      }
      $zoho.salesiq.floatbutton.visible("hide")
    }
  }
}
</script>

<template>
  <div class="default-page">
    <!-- Texto de Información. -->
    <div class="px-0 container-fluid  centered-layout">
    <div
      id="landing-banner"
      class="w-100 d-flex justify-content-center position-relative"
      :style="{backgroundImage: `url(${require('/public/img/sorteo-202502/2.jpg')})`}"
    >
      <div class="col-lg-10 d-flex px-2 flex-column align-items-center" style="margin-top: 8rem;">
        <h1 class="text-center text-primary" style="font-weight: 900; margin-bottom: 3rem; font-size: 3.5rem;">¡Parque Warner espera a alguien de tu club!</h1>

        <h2 class="text-center" style="margin-bottom: 5rem;">Cluber sortea una experiencia inolvidable en familia entre todos sus clubes.</h2>
        <button
          type="button"
          class="btn-primary btn btn-lg p-4 px-5 rounded"
          @click="scrollToForm"
        >
          <strong>Quiero que mi club participe</strong>
        </button>

      </div>
    </div>
    <div class="justify-content-center d-flex p-5" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-10 d-flex flex-column flex-md-row flex-nowrap justify-content-around align-items-center">
        <div class="col-md-5">
          <h1 class="mb-4">¿Cómo funciona el sorteo?</h1>
          <p>Cluber ofrece a los nuevos clubes clientes la posibilidad de habilitar una campaña en su perfil. Las familias pueden participar de dos formas:</p>
          <ol>
            <li><p><span class="font-weight-bold">Registros de nuevos usuarios:</span> Cada vez que un nuevo usuario (tutor o deportista) se registre en un club a través de la plataforma Cluber, recibirá una participación en el sorteo. Este proceso requiere completar los datos personales y proporcionar un método de pago válido.</p></li>
            <li><p><span class="font-weight-bold">Donativos a clubes:</span> Cada donativo de 3€ a un club en la campaña habilitada otorga una participación. Un usuario puede hacer varios donativos y obtener tantas participaciones como donativos realice. Las participaciones pueden acumularse a través de diferentes clubes.</p></li>
          </ol>
        </div>
        <div class="col-md-5">
          <img class="px-0 mx-0" :src="require('/public/img/sorteo-202502/1.jpg')"/>
        </div>
      </div>

    </div>
    <div class="justify-content-center bg-light d-flex p-5" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-8 d-flex flex-column justify-content-around align-items-center">
          <div style="max-width: 40rem;">
            <h1 class="mb-4">¿Cuáles son los beneficios para el club?</h1>
            <p><span class="font-weight-bold">Generación de ingresos:</span> Cada donativo realizado por las familias es un aporte directo al club, ayudando a financiar actividades y mejorar los recursos disponibles para los deportistas.</p>
            <p class="mb-5"><span class="font-weight-bold">Impulso a la digitalización:</span> Al registrar a los deportistas de manera digital, el club avanza en su proceso de digitalización, facilitando la gestión de inscripciones, el seguimiento del rendimiento y la comunicación eficiente con los socios y sus familias.</p>
          </div>
          <div class="col-lg-8">
            <img class="px-0" :src="require('/public/img/sorteo-202502/2.jpg')"/>
          </div>
      </div>

    </div>
    <div class="justify-content-center d-flex p-5" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-10 d-flex flex-column-reverse flex-md-row flex-nowrap justify-content-around align-items-center">
        <div class="col-md-5">
          <img class="px-0 mx-0" :src="require('/public/img/sorteo-202502/3.jpg')"/>
        </div>
        <div class="col-md-5">
          <h1 class="mb-4">¿En qué consiste el premio?</h1>
          <p>El ganador disfrutará de una experiencia Parque Warner para cuatro personas (dos adultos y dos niños) que incluye entradas al parque para 2 días consecutivos y una noche de alojamiento en el centro de Madrid.</p>
        </div>
      </div>

    </div>
    <div class="justify-content-center d-flex p-5 bg-light" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-10 d-flex flex-column flex-md-row flex-nowrap justify-content-around align-items-center">
        <div class="col-md-5">
          <h1 class="mb-4">¿Cómo participan las familias?</h1>
          <p>Las familias pueden participar en el sorteo de dos maneras:</p>
          <ol>
            <li><p><span class="font-weight-bold">Registros de nuevos usuarios:</span> Cada vez que un nuevo usuario (tutor o deportista) se registre en un club a través de la plataforma Cluber, recibirá una participación en el sorteo. Este proceso requiere completar los datos personales y proporcionar un método de pago válido.</p></li>
            <li><p><span class="font-weight-bold">Donativos al club:</span> Al acceder a la campaña de su club, las familias pueden hacer un donativo de 3€. Cada donativo les dará una participación, y pueden adquirir tantas participaciones como deseen realizando varios donativos en una misma operación.</p></li>
          </ol>
        </div>
        <div class="col-md-5">
          <img class="px-0 mx-0" :src="require('/public/img/sorteo-202502/4.jpg')"/>
        </div>
      </div>

    </div>
    <div id="formulario-inscripcion" class="justify-content-center d-flex py-5" style="padding-top: 3rem !important; padding-bottom: 7rem !important;">
      <div class="col-lg-10 d-flex flex-column align-items-center py-4" >
        <div class="px-5">
          <h1 class="mb-4 text-center">Estoy interesado en participar</h1>
          <h4 class="mt-0 mb-5 text-center">Déjanos los datos de tu club y nos pondremos en contacto contigo para darte más información</h4>
        </div>
        <iframe id="zf_div_6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg" style="max-width: 40rem" class="px-2 w-100" src="https://forms.zohopublic.eu/cluber/form/SorteoCluberParqueWarner/formperma/DUKHbhk1lnp0CcFpdltWsQzOtBZhHLJq_iNP89tszVc" />


      </div>
    </div>
    </div>
    
  </div>
</template>
<style scoped>
#landing-banner {
  background-position: center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
#landing-banner::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(255, 255, 255, 0.87);
}

ol li::marker {
  font-weight: bold;
  font-size: 1rem;
}
</style>